import {
  Dialog,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition
} from '@headlessui/react';
import {
  Bars3Icon,
  CameraIcon,
  CloudArrowDownIcon,
  DocumentTextIcon,
  GlobeAmericasIcon,
  HomeIcon,
  UserCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { Link, useForm, usePage } from '@inertiajs/react';
import { Fragment, useState } from 'react';
import { classNames } from '../utils.js';

const solutions = [
  { name: 'Analytics', href: '#' },
  { name: 'Engagement', href: '#' },
  { name: 'Security', href: '#' },
  { name: 'Integrations', href: '#' },
  { name: 'Automations', href: '#' },
  { name: 'Reports', href: '#' }
];

const navigation = [
  { name: 'Home', href: '/dashboard', icon: HomeIcon, current: false },
  {
    name: 'Overview',
    href: '#',
    icon: GlobeAmericasIcon,
    current: false
  },
  { name: 'Snapshot', href: '/snapshot', icon: CameraIcon, current: false },
  {
    name: 'Ohm Standard Review',
    href: '/ohm_standard_review',
    icon: DocumentTextIcon,
    current: false
  },
  {
    name: 'Application Downloads',
    href: '/applications',
    icon: CloudArrowDownIcon,
    current: false
  }
];

function Example() {
  return (
    <Menu>
      <MenuButton>My account</MenuButton>
      <MenuItems anchor="bottom">
        <MenuItem>
          <a className="block data-[focus]:bg-blue-100" href="/settings">
            Settings
          </a>
        </MenuItem>
        <MenuItem>
          <a className="block data-[focus]:bg-blue-100" href="/support">
            Support
          </a>
        </MenuItem>
        <MenuItem>
          <a className="block data-[focus]:bg-blue-100" href="/license">
            License
          </a>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
}

export default function FlyoutMenu() {
  const { user } = usePage().props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { delete: destroy } = useForm('');

  const submitSignoutForm = e => {
    e.preventDefault();

    destroy('/users/sign_out');
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 lg:hidden"
            onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}

                  {/* --------------------------- START: Flyout Menu ---------------------------  */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#F5F5F5] px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      {/* TODO: Re-enable once we have organization switching working */}
                      {/* <Dropdown options={['OHM Fitness', 'Amp']} /> */}
                      Ohm Fitness
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map(item => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-50 text-blue-600'
                                      : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
                                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                                  )}>
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? 'text-blue-600'
                                        : 'text-gray-400 group-hover:text-blue-600',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  {/* ---------------------------- END: Flyout Menu ----------------------------  */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden  lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
          {/* --------------------------- START: Flyout Menu ---------------------------  */}

          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r bg-[#F5F5F5] px-6 ">
            <div className="flex h-16 shrink-0 items-center ">
              {/* TODO: Re-enable once we have organization switching working */}
              {/* <Dropdown options={['OHM Fitness', 'Amp']} /> */}
              Ohm Fitness
            </div>
            <nav className="flex flex-1 flex-col">
              <ul
                role="list"
                className="flex flex-1 flex-col gap-y-7 overflow-hidden text-ellipsis">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map(item => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-50 text-[#0096EE]'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-[#0096EE]',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                          )}>
                          <item.icon
                            className={classNames(
                              item.current
                                ? 'text-[#0096EE]'
                                : 'text-gray-400 group-hover:text-[#0096EE]',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
            <Menu as="div" className="relative mb-2 inline-block text-left">
              <div>
                <MenuButton className=" flex w-full flex-row items-center justify-evenly rounded-md p-2 text-sm font-semibold  text-gray-700 hover:bg-gray-50 hover:text-[#0096EE]">
                  <UserCircleIcon className="h-8 w-8 rounded-full bg-gray-50" />
                  <span className="sr-only">Your profile</span>
                  <span aria-hidden="true">{user.email}</span>
                </MenuButton>
              </div>

              <MenuItems
                anchor="top end"
                transition
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                <div className="py-1">
                  <MenuItem className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                    <Link href="/settings" as="button">
                      Settings
                    </Link>
                  </MenuItem>
                  <MenuItem className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                    <form onSubmit={submitSignoutForm}>
                      <button type="submit">Sign out</button>
                    </form>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
          {/* ---------------------------- END: Flyout Menu ----------------------------  */}
        </div>

        <div className="sticky top-0 z-10 flex items-center justify-between gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          <a href="#">
            <span className="sr-only">Your profile</span>
            <UserCircleIcon className="h-8 w-8 rounded-full bg-gray-50" />
          </a>
        </div>
      </div>
    </>
  );
}
