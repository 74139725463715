import { useRef, useEffect } from 'react';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const useIsMount = () => {
  const isMountRef = useRef(true);

  useEffect(() => {
    isMountRef.current = false;
  }, []);

  return isMountRef.current;
};

export function isAdmin(user) {
  return user.role === 'admin';
}
