import FlyoutMenu from './FlyoutMenu.jsx';

const Layout = ({ children }) => {
  return (
    <>
      <FlyoutMenu />
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
    </>
  );
};

export default page => <Layout>{page}</Layout>;
